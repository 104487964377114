import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import OutstandingInvoices from './OutstandingInvoices';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import PaymentsAgainOutstandings from './PaymentsAgainOutstandings';

const PaymentDistributionForm = () => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [subValidMsg, setSubValidMsg] = useState();
    const currCompIdSel = localStorage.getItem('currentSelectedCompId');

    const { data, error, isLoaded } = GetRequest(`/Scheme/GetRunningSchemesForCompany/${distID}/${currCompIdSel}`);

    const [advPay, setAdvPay] = useState({});

    if (error !== null) {
        return <Error data="All Running Schemes For Company & Payment Against Outstandings" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    const notify = () => toast.error('Your Total value paid less than entered value !', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccess = () => toast.success('Please enter OTP recived on your Mobile !', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });

    document.getElementById('Payment-submit-stepper-head').classList.remove('active');
    document.getElementById('Payment-distribution-stepper-head').classList.add('active');
    document.getElementById('Payment-summary-stepper-head').classList.remove('active');

    setTimeout(function () {
        document.getElementById("totalePayValueDistHead").value = document.getElementById("totalePayValueDis").value;
        //document.getElementById("advPayValueDistHead").value = document.getElementById("advPayValueDis").value;
    }, 1500);

    const minusTotalAmoutPaid = (event) => {
        event.preventDefault();
        const currVal = event.target.value;
        const tlPaidVal = document.getElementById("totalePayValueDistHead").value;
        const tlVal = document.getElementById("advPayValueDistHead").value;
        const updateTVal = tlVal - currVal;
        if (parseInt(tlVal) < 0) {
            notify();
            document.getElementById("advPayValueDistHead").classList.add('text-danger');
        }
        document.getElementById("advPayValueDistHead").value = updateTVal;
    }

    const handlePaymentDistributionSubmit = (event) => {

        event.preventDefault();

        const formData = {};
        formData.schemeDistributionDetails = [];
        formData.invoiceDistributionDetails = [];
        formData.costCentreWisePaymentDistribution = [];

        const inputsPaymentAgainstOutStanding = document.querySelectorAll('.payOutText');

        inputsPaymentAgainstOutStanding.forEach(input => {
            if (input.value && input.value.trim() !== '' && input.value.trim() !== '0') {
                const inputValue = parseFloat(input.value.trim());
                if (!isNaN(inputValue) && inputValue !== 0) {
                    const sDTODateItem = {
                        costCentreId: input.id, // ID of the textbox
                        paymentId: document.getElementById("paymentIdResData").value,
                        paidAmountAgainstCostCentre: input.value, // Value of the textbox
                        comments: "" // Comments property (can be populated as needed),
                    };
                    formData.costCentreWisePaymentDistribution.push(sDTODateItem);
                }
            }
        });

        const inputsSchemeWisePayment = document.querySelectorAll('.payOutTextScheme');

        inputsSchemeWisePayment.forEach(input => {
            if (input.value && input.value.trim() !== '' && input.value.trim() !== '0') {
                const inputValue = parseFloat(input.value.trim());
                if (!isNaN(inputValue) && inputValue !== 0) {
                    const sDTODateItemScheme = {
                        paymentId: document.getElementById("paymentIdResData").value,
                        schemeId: input.getAttribute('data-scheme-id'),
                        schemeDateWiseDetailsId: input.id,
                        paidAmountForScheme: input.value,
                        comments: "",
                        schemName: ""
                    };
                    formData.schemeDistributionDetails.push(sDTODateItemScheme);
                }
            }
        });

        // Add the object to the array

        //addAdvancedPaymentAmount();
        //formData.AdvancePayment = document.getElementById("advPayValueDistHead").value;
        formData.paymentId = document.getElementById("paymentIdResData").value;
        formData.totalPayment = document.getElementById("totalePayValueDis").value;

        axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/Payment/SubmitPaymentDistributionDetails", formData, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.isSuccess === false) {
                        setSubValidMsg(response.data.message);
                        document.getElementById('clickSubmitValidError').classList.remove('hide');
                        document.getElementById('loaderFormSubmit').classList.add('hide');
                        document.getElementById('clickSubmitValidError').classList.remove('hide');
                        // closeErrorMsg();
                        //toggleServerError();
                    } else {

                        navigate("/NewPayment/PaymentSummary");
                    }
                } else {
                    document.getElementById('loaderFormSubmit').classList.add('hide');
                }
            }).catch((response) => {
                //console.log(response.statusText);
                loaderToggle();
                document.getElementById('loaderFormSubmit').classList.add('hide');
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
            });

    }
    function closeErrorMsg() {
        document.getElementById('clickSubmitValidError').classList.add('hide');
    }
    function toggleServerError() {
        setTimeout(function () {
            document.getElementById('clickSubmitValidError').classList.add('hide');
        }, 10000);
    }

    function loaderToggle() {
        document.getElementById('loaderFormSubmit').classList.toggle('hide');
    }

    return (
        <>
            <div className="col-12">
                <div className="card card-warning">
                    <div className="card-header">
                        <div className='row'>
                            <div className='col-md-6 col-sm-6 col-6'>
                                <div className='form-group'>
                                    <label>Total Payment <input type="text" id='totalePayValueDistHead' className="form-control" disabled value="" /></label>
                                </div>
                            </div>
                            {/* <div className='col-md-6 col-sm-6 col-6'>
                                <div className='form-group'> 
                                    <label>Advanced Payment<input type="text" id='advPayValueDistHead' className="form-control" disabled value="" /></label>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card-body nopadPaydist">
                        <h5 className='payDistHead'>Payment Distribution Across Schemes</h5>

                        <form onSubmit={handlePaymentDistributionSubmit} novalidate>
                            <div id="accordion">
                                {
                                    data.result && data.result.map((item, index) => (
                                        <div className="card paydistheadCardnopad ">
                                            <div className="card-header hdcolacc text-white" data-bs-toggle="collapse" href={`#collapse${item.id}`}>
                                                <a className="btn" >
                                                    {item.name} - {item.schemeType}
                                                </a>
                                                <a className="btn dp-right-mt5 accUIIcon">
                                                    <i className="fa fa-angle-double-down"></i>
                                                    <i className="fa fa-angle-double-up hide"></i>
                                                </a>
                                            </div>
                                            <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                                                <div className="card-body">

                                                    {
                                                        item.schemeDTODateDetailsItems && item.schemeDTODateDetailsItems.map((itemInner, index1) => (
                                                            <div className='row sepSecLine'>
                                                                <div className='col-sm-6'> <b> {itemInner.fromDateString}</b> To <b>{itemInner.toDateString}</b>
                                                                    - Discount : <b>{itemInner.discountAmount} % </b><br />
                                                                    <b>Discount Type : {itemInner.discountType}</b> <br />
                                                                    <b>Details :</b> {itemInner.details}<br />
                                                                </div>

                                                                <div className='col-sm-6 mobpadpadpad'>
                                                                    {itemInner.isActive === false ? <label className='text-danger'>Offer Period Expired </label> :
                                                                        <input type='number' id={itemInner.id} data-scheme-id={item.id} className='form-control payOutTextScheme' name='paidAmountForScheme' onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })} />}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <PaymentsAgainOutstandings />

                            {/* <OutstandingInvoices funcname={handleChangeIDD} />  */}
                            <div className="row ">
                                <div className="col-md-6">
                                    <div className="card-body myBgFoot">
                                        <input type="submit" className="btn btn-primary float-right submit-btn-dis-pay" value="Save Distribution Details" />
                                    </div>
                                </div>
                                <div className='col-md-6 '>
                                    <span id="clickSubmitValidError" className='hide'>
                                        <div className="alert alert-danger alert-dismissible">
                                            <button type="button" className="btn-close" onClick={closeErrorMsg} ></button>
                                            <label> {subValidMsg} </label>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </form>

                        <div id='loaderFormSubmit' className='col-md-12 hide'>
                            <div id="loaderScreen" ></div>
                        </div>

                        <ToastContainer />
                    </div>
                </div>
            </div>
        </>
    )

}

export default PaymentDistributionForm



// const handleChangePAD = (event) => {
//     event.preventDefault();
//     const curId = event.currentTarget.id;
//     if (formData.costCentreWisePaymentDistribution.length !== 0) {
//         if (formData.costCentreWisePaymentDistribution.some(itk => itk.costCentreId === curId)) {
//             formData.costCentreWisePaymentDistribution.forEach((itchk, index) => {
//                 if (itchk.costCentreId === curId) {
//                     formData.costCentreWisePaymentDistribution[index].costCentreId = event.currentTarget.id;
//                     formData.costCentreWisePaymentDistribution[index].paymentId = document.getElementById("paymentIdResData").value
//                     formData.costCentreWisePaymentDistribution[index].paidAmountAgainstCostCentre = event.target.value;
//                 }
//             });

//         } else {
//             const sDTODateItem = {
//                 costCentreId: event.currentTarget.id,
//                 paymentId: document.getElementById("paymentIdResData").value,
//                 paidAmountAgainstCostCentre: event.target.value,
//                 comments: "",
//             }

//             formData.costCentreWisePaymentDistribution.push(sDTODateItem);
//             // minusTotalAmoutPaid(event);
//             createJsonData();
//         }
//     } else {
//         const sDTODateItem = {
//             costCentreId: event.currentTarget.id,
//             paymentId: document.getElementById("paymentIdResData").value,
//             paidAmountAgainstCostCentre: event.target.value,
//             comments: "",
//         }
//         formData.costCentreWisePaymentDistribution.push(sDTODateItem);
//         // minusTotalAmoutPaid(event);
//         createJsonData();
//     }

// }
// const handleChangeIDD = (event) => {
//     event.preventDefault();
//     const invoiceData = {
//         paymentId: document.getElementById("paymentIdResData").value,
//         invoiceId: event.target.id,
//         paidAmountAgainstInvoice: event.target.value,
//         comments: "",
//         invoiceNumber: "",
//         orderNumber: ""
//     }
//     formData.invoiceDistributionDetails.push(invoiceData);
//     // minusTotalAmoutPaid(event);
//     createJsonData();
// }

// const handleChangeSD = (event) => {
//     event.preventDefault();
//     const curId = event.currentTarget.id;
//     if (formData.schemeDistributionDetails.length !== 0) {
//         if (formData.schemeDistributionDetails.some(itk => itk.schemeDateWiseDetailsId === curId)) {
//             formData.schemeDistributionDetails.forEach((itchk, index) => {
//                 if (itchk.schemeDateWiseDetailsId === curId) {
//                     formData.schemeDistributionDetails[index].schemeDateWiseDetailsId = event.currentTarget.id;
//                     formData.schemeDistributionDetails[index].paymentId = document.getElementById("paymentIdResData").value
//                     formData.schemeDistributionDetails[index].paidAmountForScheme = event.target.value;
//                 }
//             });

//         } else {
//             const schemesDetailsData = {
//                 paymentId: document.getElementById("paymentIdResData").value,
//                 schemeId: event.target.getAttribute('data-scheme-id'),
//                 schemeDateWiseDetailsId: event.target.id,
//                 paidAmountForScheme: event.target.value,
//                 comments: "",
//                 schemName: ""
//             }
//             formData.schemeDistributionDetails.push(schemesDetailsData);
//             createJsonData();
//         }
//     } else {
//         const schemesDetailsData = {
//             paymentId: document.getElementById("paymentIdResData").value,
//             schemeId: event.target.getAttribute('data-scheme-id'),
//             schemeDateWiseDetailsId: event.target.id,
//             paidAmountForScheme: event.target.value,
//             comments: "",
//             schemName: ""
//         }
//         formData.schemeDistributionDetails.push(schemesDetailsData);
//         createJsonData();
//     }

// }

// function addAdvancedPaymentAmount() {
//     // formData.AdvancePayment = document.getElementById("advPayValueDistHead").value;
// }
// function createJsonData() {
//     formData.paymentId = document.getElementById("paymentIdResData").value;
//     formData.totalPayment = document.getElementById("amountPaidResData").value;

// }