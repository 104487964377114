import React from 'react'
import GetRequest from '../Services/APIRequest'
import { useState } from "react";


const AccountsSummaryTotalAndCostCentreWise = ({ filterData }) => {
  // const [filterDataState, setFilterData] = useState(filterData); // Add state for filterData

  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const bpuID = localStorage.getItem('userId');

  const { data } = GetRequest(`/Account/GetAccountsSummaryTotalAndCostCentreWise/BPUser/${parseInt(bpuID)}/${filterData}/fromDt/toDate`);


  return (
    <div>

      <div className="row">
        <div className="col-md-12">
          <div className={`card card-warning`}>
            <div className="card-header topnav">
              <h3 className="card-title">  Account Summary  </h3>
            </div>
            {
              data.result && data.result.totalAmounts.map((item, index) => (
                <div className="row">
                  <div className="col-md-12">
                    <div className="row card-body">
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <h6><b> {item.companyName}</b></h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-4">
                          <div className={`small-box bg-info`}>
                            <div className="inner">
                              <h4>Sales <sub ></sub></h4>
                              <p>
                                {item.salesAmount}      </p>
                            </div>
                            {/* <div className="icon">
                            {<i className=" fa fa-inr"></i>}
                          </div> */}
                          </div>
                        </div>

                        <div className="col-lg-3 col-4">
                          <div className={`small-box bg-confirmed`}>
                            <div className="inner">
                              <h4>Receipts <sub ></sub></h4>
                              <p>
                                {item.receiptsAmount}      </p>
                            </div>
                            {/* <div className="icon">
                            {<i className=" fa fa-inr"></i>}
                          </div> */}
                          </div>
                        </div>

                        <div className="col-lg-3 col-4">
                          <div className={`small-box bg-approved`}>
                            <div className="inner">
                              <h4>Balance <sub ></sub></h4>
                              <p>
                                {item.outstandingAmount}      </p>
                            </div>
                            {/* <div className="icon">
                            {<i className=" fa fa-inr"></i>}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div >

      <div className="row">
        <div className="col-md-12">
          <div className={`card card-primary`}>
            <div className="card-header topnav">
              <h3 className="card-title">  Booster Summary   </h3>
            </div>
            <div className="row card-body">
              <table class='table table-bordered'>
                <thead>
                  <th>Name</th><th>Sales</th><th>Receipts</th><th>Balance</th>
                </thead>
                {
                  data.result && data.result.costCentreWiseSalesReceiptsOutStandingsBooster.map((item, index) => (
                    <tr>
                      <td>{item.name} </td>
                      <td>{item.amountDetails.salesAmount}</td>
                      <td>{item.amountDetails.receiptsAmount} </td>
                      <td>{item.amountDetails.outstandingAmount}</td>
                    </tr>
                  ))
                }
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className={`card card-primary`}>
            <div className="card-header topnav">
              <h3 className="card-title">  Paris Summary   </h3>
            </div>
            <div className="row card-body">
              <table class='table table-bordered'>
                <thead>
                  <th>Name</th><th>Sales</th><th>Receipts</th><th>Outstanding</th>
                </thead>
                {
                  data.result && data.result.costCentreWiseSalesReceiptsOutStandingsParis.map((item, index) => (
                    <tr>
                      <td>{item.name} </td>
                      <td>{item.amountDetails.salesAmount}</td>
                      <td>{item.amountDetails.receiptsAmount} </td>
                      <td>{item.amountDetails.outstandingAmount} </td>
                    </tr>
                  ))
                }
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountsSummaryTotalAndCostCentreWise